//@flow
import gql from 'graphql-tag';

const list = gql`
  query ComplianceStandardCriteriaList {
    compliance_standard_criteria_list {
      compliance_standard_criteria {
        compliance_standard
        criterion
        description
        title
        link
        referenced_by_policy_rule_type_ids
      }
    }
  }
`;

export const ComplianceCategories = {
  OWASP_A: 'OWASP_A',
  OWASP_M: 'OWASP_M',
  OWASP_W: 'OWASP_W',
  OWASP_R: 'OWASP_R',
  OWASP_MSTG: 'OWASP_MSTG',
  PCI_DSS: 'PCI_DSS',
  NIST_800_53: 'NIST_800_53',
  FFIEC_VC2: 'FFIEC_VC2',
};

export default {
  list,
};
