// @flow
import {
  PolicyComplianceStandards,
  type PolicyComplianceStandardType,
  PolicyComplianceStandardCriteria,
  type PolicyComplianceStandardCriteriaType,
  type PolicyRuleType,
  type PolicyRuleTypeList,
} from '@dt/horizon-api';

import {
  type ComplianceRuleTypeDecorated,
  type ComplianceRuleGroupDescription,
  type ComplianceRuleGroupDecorated,
} from './types';

// Icons
import OWASP_ICON_FLAT from './icons/OWASP_ICON_FLAT.svg';
import PCI_ICON from './icons/PCI_ICON.svg';
import NIST_800_53 from './icons/NIST_800_53.svg';
import FFIEC_VC2 from './icons/FFIEC_VC2.png';

const complianceStandardToIconLegend: $Exact<
  $ObjMap<typeof PolicyComplianceStandards, <V>() => string>,
> = {
  [PolicyComplianceStandards.OWASP]: OWASP_ICON_FLAT,
  [PolicyComplianceStandards.PCI_DSS]: PCI_ICON,
  [PolicyComplianceStandards.NIST_800_53]: NIST_800_53,
  [PolicyComplianceStandards.FFIEC_VC2]: FFIEC_VC2,
};

export const getIconFromComplianceStandard = (
  standard: PolicyComplianceStandardType,
) => {
  const icon = complianceStandardToIconLegend[standard];
  if (icon) return icon;
  else {
    throw new Error(
      `Couldn't get icon for Compliance Standard ${standard}, complianceStandardToIconLegend is likely out of date`,
    );
  }
};

const complianceStandardToDisplayTextLegend: $Exact<
  $ObjMap<typeof PolicyComplianceStandards, <V>() => string>,
> = {
  [PolicyComplianceStandards.OWASP]: 'OWASP',
  [PolicyComplianceStandards.PCI_DSS]: 'PCI DSS',
  [PolicyComplianceStandards.NIST_800_53]: 'NIST 800-53',
  [PolicyComplianceStandards.FFIEC_VC2]: 'FFIEC VC2',
};

export const getDisplayTextFromComplianceStandard = (
  standard: PolicyComplianceStandardType,
) => {
  const text = complianceStandardToDisplayTextLegend[standard];
  if (text) return text;
  else {
    throw new Error(
      `Couldn't get Display Text for Compliance Standard ${standard}, PolicyComplianceStandardsType is likely out of date`,
    );
  }
};

const complianceStandardCriteriaToDisplayTextLegend: $Exact<
  $ObjMap<typeof PolicyComplianceStandardCriteria, <V>() => string>,
> = {
  // OWASP API Top 10 2019
  [PolicyComplianceStandardCriteria.OWASP_A1_2019]: 'A1:2019',
  [PolicyComplianceStandardCriteria.OWASP_A2_2019]: 'A2:2019',
  [PolicyComplianceStandardCriteria.OWASP_A3_2019]: 'A3:2019',
  [PolicyComplianceStandardCriteria.OWASP_A4_2019]: 'A4:2019',
  [PolicyComplianceStandardCriteria.OWASP_A5_2019]: 'A5:2019',
  [PolicyComplianceStandardCriteria.OWASP_A6_2019]: 'A6:2019',
  [PolicyComplianceStandardCriteria.OWASP_A7_2019]: 'A7:2019',
  [PolicyComplianceStandardCriteria.OWASP_A8_2019]: 'A8:2019',
  [PolicyComplianceStandardCriteria.OWASP_A9_2019]: 'A9:2019',
  [PolicyComplianceStandardCriteria.OWASP_A10_2019]: 'A10:2019',
  // OWASP Web Top 10 2017
  [PolicyComplianceStandardCriteria.OWASP_W1_2017]: 'WEB1:2017',
  [PolicyComplianceStandardCriteria.OWASP_W2_2017]: 'WEB2:2017',
  [PolicyComplianceStandardCriteria.OWASP_W3_2017]: 'WEB3:2017',
  [PolicyComplianceStandardCriteria.OWASP_W4_2017]: 'WEB4:2017',
  [PolicyComplianceStandardCriteria.OWASP_W5_2017]: 'WEB5:2017',
  [PolicyComplianceStandardCriteria.OWASP_W6_2017]: 'WEB6:2017',
  [PolicyComplianceStandardCriteria.OWASP_W7_2017]: 'WEB7:2017',
  [PolicyComplianceStandardCriteria.OWASP_W8_2017]: 'WEB8:2017',
  [PolicyComplianceStandardCriteria.OWASP_W9_2017]: 'WEB9:2017',
  [PolicyComplianceStandardCriteria.OWASP_W10_2017]: 'WEB10:2017',
  // OWASP Cloud Risks 2011
  [PolicyComplianceStandardCriteria.OWASP_R1_2011]: 'CLOUD1:2011',
  [PolicyComplianceStandardCriteria.OWASP_R2_2011]: 'CLOUD2:2011',
  [PolicyComplianceStandardCriteria.OWASP_R3_2011]: 'CLOUD3:2011',
  [PolicyComplianceStandardCriteria.OWASP_R4_2011]: 'CLOUD4:2011',
  [PolicyComplianceStandardCriteria.OWASP_R5_2011]: 'CLOUD5:2011',
  [PolicyComplianceStandardCriteria.OWASP_R6_2011]: 'CLOUD6:2011',
  [PolicyComplianceStandardCriteria.OWASP_R7_2011]: 'CLOUD7:2011',
  [PolicyComplianceStandardCriteria.OWASP_R8_2011]: 'CLOUD8:2011',
  [PolicyComplianceStandardCriteria.OWASP_R9_2011]: 'CLOUD9:2011',
  [PolicyComplianceStandardCriteria.OWASP_R10_2011]: 'CLOUD10:2011',
  // PCI DSS v 3.2.1
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_aA_2_1]: 'A2.1',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a1_2_1]: '1.2.1',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a1_3]: '1.3',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a2_3]: '2.3',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a2_2_3]: '2.2.3',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a3_4]: '3.4',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a4_1]: '4.1',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a6_1]: '6.1',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a6_5_10]: '6.5.10',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a6_6]: '6.6',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a8_7]: '8.7',
  [PolicyComplianceStandardCriteria.PCI_DSS_v3_2_1_a10_1]: '10.1',
  // NIST_800_53
  [PolicyComplianceStandardCriteria.NIST_800_53_AC_1]: 'AC-1',
  [PolicyComplianceStandardCriteria.NIST_800_53_AC_4]: 'AC-4',
  [PolicyComplianceStandardCriteria.NIST_800_53_AU_11]: 'AU-11',
  [PolicyComplianceStandardCriteria.NIST_800_53_SA_2]: 'SA-2',
  [PolicyComplianceStandardCriteria.NIST_800_53_SA_4]: 'SA-4',
  [PolicyComplianceStandardCriteria.NIST_800_53_SC_1]: 'SC-1',
  [PolicyComplianceStandardCriteria.NIST_800_53_SC_7]: 'SC-7',
  [PolicyComplianceStandardCriteria.NIST_800_53_SC_12]: 'SC-12',
  [PolicyComplianceStandardCriteria.NIST_800_53_SC_16]: 'SC-16',
  [PolicyComplianceStandardCriteria.NIST_800_53_SI_1]: 'SI-1',
  [PolicyComplianceStandardCriteria.NIST_800_53_SI_2]: 'SI-2',
  [PolicyComplianceStandardCriteria.NIST_800_53_CM_2]: 'CM-2',
  [PolicyComplianceStandardCriteria.NIST_800_53_CM_4]: 'CM-4',
  // OWASP M* 2014
  [PolicyComplianceStandardCriteria.OWASP_M1_2014]: 'MOBILE1:2014',
  [PolicyComplianceStandardCriteria.OWASP_M2_2014]: 'MOBILE2:2014',
  [PolicyComplianceStandardCriteria.OWASP_M3_2014]: 'MOBILE3:2014',
  [PolicyComplianceStandardCriteria.OWASP_M4_2014]: 'MOBILE4:2014',
  [PolicyComplianceStandardCriteria.OWASP_M5_2014]: 'MOBILE5:2014',
  [PolicyComplianceStandardCriteria.OWASP_M6_2014]: 'MOBILE6:2014',
  [PolicyComplianceStandardCriteria.OWASP_M7_2014]: 'MOBILE7:2014',
  [PolicyComplianceStandardCriteria.OWASP_M8_2014]: 'MOBILE8:2014',
  [PolicyComplianceStandardCriteria.OWASP_M9_2014]: 'MOBILE9:2014',
  [PolicyComplianceStandardCriteria.OWASP_M10_2014]: 'MOBILE10:2014',
  // OWASP M* 2016
  [PolicyComplianceStandardCriteria.OWASP_M1_2016]: 'MOBILE1:2016',
  [PolicyComplianceStandardCriteria.OWASP_M2_2016]: 'MOBILE2:2016',
  [PolicyComplianceStandardCriteria.OWASP_M3_2016]: 'MOBILE3:2016',
  [PolicyComplianceStandardCriteria.OWASP_M4_2016]: 'MOBILE4:2016',
  [PolicyComplianceStandardCriteria.OWASP_M5_2016]: 'MOBILE5:2016',
  [PolicyComplianceStandardCriteria.OWASP_M6_2016]: 'MOBILE6:2016',
  [PolicyComplianceStandardCriteria.OWASP_M7_2016]: 'MOBILE7:2016',
  [PolicyComplianceStandardCriteria.OWASP_M8_2016]: 'MOBILE8:2016',
  [PolicyComplianceStandardCriteria.OWASP_M9_2016]: 'MOBILE9:2016',
  [PolicyComplianceStandardCriteria.OWASP_M10_2016]: 'MOBILE10:2016',
  // OWASP MASTG*
  [PolicyComplianceStandardCriteria.OWASP_MSTG_ARCH_1]: 'MSTG-ARCH-1',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_ARCH_2]: 'MSTG-ARCH-2',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_ARCH_3]: 'MSTG-ARCH-3',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_ARCH_4]: 'MSTG-ARCH-4',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_ARCH_5]: 'MSTG-ARCH-5',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_ARCH_6]: 'MSTG-ARCH-6',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_ARCH_7]: 'MSTG-ARCH-7',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_ARCH_8]: 'MSTG-ARCH-8',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_ARCH_9]: 'MSTG-ARCH-9',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_ARCH_10]: 'MSTG-ARCH-10',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_ARCH_11]: 'MSTG-ARCH-11',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_ARCH_12]: 'MSTG-ARCH-12',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_1]: 'MSTG-STORAGE-1',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_2]: 'MSTG-STORAGE-2',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_3]: 'MSTG-STORAGE-3',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_4]: 'MSTG-STORAGE-4',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_5]: 'MSTG-STORAGE-5',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_6]: 'MSTG-STORAGE-6',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_7]: 'MSTG-STORAGE-7',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_8]: 'MSTG-STORAGE-8',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_9]: 'MSTG-STORAGE-9',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_10]: 'MSTG-STORAGE-10',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_11]: 'MSTG-STORAGE-11',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_12]: 'MSTG-STORAGE-12',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_13]: 'MSTG-STORAGE-13',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_14]: 'MSTG-STORAGE-14',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_STORAGE_15]: 'MSTG-STORAGE-15',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CRYPTO_1]: 'MSTG-CRYPTO-1',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CRYPTO_2]: 'MSTG-CRYPTO-2',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CRYPTO_3]: 'MSTG-CRYPTO-3',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CRYPTO_4]: 'MSTG-CRYPTO-4',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CRYPTO_5]: 'MSTG-CRYPTO-5',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CRYPTO_6]: 'MSTG-CRYPTO-6',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_AUTH_1]: 'MSTG-AUTH-1',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_AUTH_2]: 'MSTG-AUTH-2',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_AUTH_3]: 'MSTG-AUTH-3',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_AUTH_4]: 'MSTG-AUTH-4',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_AUTH_5]: 'MSTG-AUTH-5',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_AUTH_6]: 'MSTG-AUTH-6',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_AUTH_7]: 'MSTG-AUTH-7',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_AUTH_8]: 'MSTG-AUTH-8',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_AUTH_9]: 'MSTG-AUTH-9',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_AUTH_10]: 'MSTG-AUTH-10',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_AUTH_11]: 'MSTG-AUTH-11',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_AUTH_12]: 'MSTG-AUTH-12',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_NETWORK_1]: 'MSTG-NETWORK-1',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_NETWORK_2]: 'MSTG-NETWORK-2',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_NETWORK_3]: 'MSTG-NETWORK-3',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_NETWORK_4]: 'MSTG-NETWORK-4',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_NETWORK_5]: 'MSTG-NETWORK-5',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_NETWORK_6]: 'MSTG-NETWORK-6',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_PLATFORM_1]: 'MSTG-PLATFORM-1',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_PLATFORM_2]: 'MSTG-PLATFORM-2',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_PLATFORM_3]: 'MSTG-PLATFORM-3',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_PLATFORM_4]: 'MSTG-PLATFORM-4',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_PLATFORM_5]: 'MSTG-PLATFORM-5',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_PLATFORM_6]: 'MSTG-PLATFORM-6',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_PLATFORM_7]: 'MSTG-PLATFORM-7',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_PLATFORM_8]: 'MSTG-PLATFORM-8',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_PLATFORM_9]: 'MSTG-PLATFORM-9',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_PLATFORM_10]: 'MSTG-PLATFORM-10',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_PLATFORM_11]: 'MSTG-PLATFORM-11',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CODE_1]: 'MSTG-CODE-1',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CODE_2]: 'MSTG-CODE-2',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CODE_3]: 'MSTG-CODE-3',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CODE_4]: 'MSTG-CODE-4',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CODE_5]: 'MSTG-CODE-5',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CODE_6]: 'MSTG-CODE-6',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CODE_7]: 'MSTG-CODE-7',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CODE_8]: 'MSTG-CODE-8',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_CODE_9]: 'MSTG-CODE-9',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_1]:
    'MSTG-RESILIENCE-1',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_2]:
    'MSTG-RESILIENCE-2',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_3]:
    'MSTG-RESILIENCE-3',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_4]:
    'MSTG-RESILIENCE-4',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_5]:
    'MSTG-RESILIENCE-5',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_6]:
    'MSTG-RESILIENCE-6',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_7]:
    'MSTG-RESILIENCE-7',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_8]:
    'MSTG-RESILIENCE-8',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_9]:
    'MSTG-RESILIENCE-9',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_10]:
    'MSTG-RESILIENCE-10',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_11]:
    'MSTG-RESILIENCE-11',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_12]:
    'MSTG-RESILIENCE-12',
  [PolicyComplianceStandardCriteria.OWASP_MSTG_RESILIENCE_13]:
    'MSTG-RESILIENCE-13',
  // FFIEC Compliance – V.C.2(c)
  [PolicyComplianceStandardCriteria.FFIEC_VC2_REQUIREMENT_1]: 'FFIEC:REQ1',
  [PolicyComplianceStandardCriteria.FFIEC_VC2_REQUIREMENT_2]: 'FFIEC:REQ2',
  [PolicyComplianceStandardCriteria.FFIEC_VC2_REQUIREMENT_3]: 'FFIEC:REQ3',
  [PolicyComplianceStandardCriteria.FFIEC_VC2_REQUIREMENT_4]: 'FFIEC:REQ4',
  [PolicyComplianceStandardCriteria.FFIEC_VC2_REQUIREMENT_5]: 'FFIEC:REQ5',
  [PolicyComplianceStandardCriteria.FFIEC_VC2_REQUIREMENT_6]: 'FFIEC:REQ6',
  [PolicyComplianceStandardCriteria.FFIEC_VC2_REQUIREMENT_7]: 'FFIEC:REQ7',
  [PolicyComplianceStandardCriteria.FFIEC_VC2_REQUIREMENT_8]: 'FFIEC:REQ8',
  [PolicyComplianceStandardCriteria.FFIEC_VC2_REQUIREMENT_9]: 'FFIEC:REQ9',
};

export const getDisplayTextFromComplianceStandardCriteria = (
  criteria: PolicyComplianceStandardCriteriaType,
) => {
  const text = complianceStandardCriteriaToDisplayTextLegend[criteria];

  if (text) return text;
  else {
    throw new Error(
      `Couldn't get Display Text for Compliance Standard Criteria ${criteria}, PolicyComplianceStandardCriteriaType is likely out of date`,
    );
  }
};
const decorateComplianceRuleType = (
  complianceRuleType: $Diff<PolicyRuleType, { group: mixed, ... }>,
  policyRuleTypes: PolicyRuleTypeList,
): ComplianceRuleTypeDecorated => {
  const { compliance_policy_references } = complianceRuleType;

  // Length of compliance_policy_references must be 1! This is verified by the unit test that these compliance rules come from
  const ruleTypeComplianceReferenceCriteria =
    compliance_policy_references[0].compliance_standard_criteria;

  const related_rule_types = policyRuleTypes.filter(rt =>
    rt.compliance_policy_references.find(
      ref =>
        ref.compliance_standard_criteria ===
        ruleTypeComplianceReferenceCriteria,
    ),
  );

  return {
    ...complianceRuleType,
    related_rule_types: related_rule_types || [],
  };
};

export const decorateComplianceRuleGroup = (
  complianceRuleGroup: ComplianceRuleGroupDescription,
  policyRuleTypes: PolicyRuleTypeList,
): ComplianceRuleGroupDecorated => {
  const { compliance_rule_types } = complianceRuleGroup;

  const compliance_rule_types_decorated = compliance_rule_types.map(crt =>
    decorateComplianceRuleType(crt, policyRuleTypes),
  );

  return {
    ...complianceRuleGroup,
    compliance_rule_types_decorated: compliance_rule_types_decorated || [],
  };
};
